import { Error } from "../"

export default user => {

    if (!user) {
        Error(
            null,
            400,
        )
    }

    const user_settings = user.settings

    return user_settings
    
}