import dark_instagram from "./theme/dark_instagram"
import dark_purple from "./theme/dark_purple"
import dark_green from "./theme/dark_green"
import dark_blue from "./theme/dark_blue"
import dark_pink from "./theme/dark_pink"
import dark_red from "./theme/dark_red"

export default {

    // Dark Mode
    dark: {

        dark_blue,
        dark_purple,
        dark_pink,
        dark_green,
        dark_red,
        dark_instagram,

    },

    // Light Mode
    light: {



    }

}