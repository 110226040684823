import { languages, getPreferredLanguage } from '../'

export default (user, path, replacement_array) => {

    // eslint-disable-next-line
    var l = languages
    // eslint-disable-next-line
    var language = user ? getPreferredLanguage(user) ? getPreferredLanguage(user) : 'en' : 'en'

    // eslint-disable-next-line
    const translation = eval(`
        l[language]?.components?.${path?.replace(/\./g, '?.')} || '%TRANSLATION UNSET! ' + path + ' in ' + language + '%'
    `)?.replace(/%{\w+}/g, (variable) => {
        if (!replacement_array) {
            new Error(
                variable,
                301,
            )
            return
        }
        for (let replacement_iterator in replacement_array) {
            let replacement = replacement_array[replacement_iterator]
            if (replacement.value[variable.match(/\w+/, 'g')]) {
                return replacement.value
            }
            if (replacement_iterator === replacement_array.length - 1) {
                new Error(
                    variable,
                    300,
                )
                return variable
            }
        }
    }) || null
    
    return translation

}