export default (error_message, error_code) => {

    const code_content = {
        // getUserSettingsByRoot
        100: "Following root in getUserSettingsByRoot doesnt exist:",

        // Typeof error
        200: "Specified variable is not an array!\nVariable type:",

        // getTranslation
        300: "Following variable is not specified in getTranslation replacement_array:",
        301: "Repleacement_array in getTranslation is undefined\nVariable:",

        // User
        400: "User is not specified!\nUser output:",

        // Gradiant
        500: "Gradiant degrees is not a number or not specified!\nDegrees output:",
        501: "Primary gradiant color is not specified!\nPrimary color:",
        502: "Secondary gradiant color is not specified!\nSecondary color:",

        // Role
        600: "Callback is not a function!\nCallback type:"
    }

    const error_content = code_content[error_code] || "Error code has no context\nError message:"

    console.error(error_content, error_message, "\n", {error_code})

}