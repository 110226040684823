export default {

    primary: "#020805",
    secondary_darker: "#061a12",
    secondary: "#0e3122",
    deactivated: "#0c6e32",
    interaction: "#03a140",
    border: "#2a3b31",
    inactive: "#849189",
    text: "#FAFAFA",

}