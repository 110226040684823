import { getUserSettings, Error } from '../'

export default (user, path) => {

    if (!user) return

    const user_settings = getUserSettings(user).data
    const path_start = `users/${user.uid}/settings/data`

    var result
    const Result = (res, ref) => {
        return { 
            res,
            ref
        }
    }

    if (!path && typeof path !== 'array') {
        new Error(
            path,
            200,
        )
        return Result(
            "error",
            200
        )
    }

    if (path[0]) {

        for (let setting_iterator in user_settings) {
            let setting = user_settings[setting_iterator]
            let settings_length = user_settings.length

            if (setting.name === path[0]) {

                if (path[1]) {

                    for (let module_iterator in setting.modules) {
                        let module = setting.modules[module_iterator]
                        let modules_length = setting.modules.length

                        if (module.name === path[1]) {

                            if (path[2]) {

                                for (let option_iterator in module.options) {
                                    let option = module.options[option_iterator]
                                    let options_length = module.options.length

                                    if (option.name === path[2]) {

                                        if (path[3]) {

                                            result = Result(
                                                option.value,
                                                `${path_start}/${setting_iterator}/modules/${module_iterator}/options/${option_iterator}/value`,
                                            )
                                            break;

                                        } else {

                                            result = Result(
                                                option,
                                                `${path_start}/${setting_iterator}/modules/${module_iterator}/options/${option_iterator}`,
                                            )
                                            break;

                                        }

                                    } else if (option_iterator === options_length - 1) {

                                        new Error(
                                            'options ' + path[2],
                                            100,
                                        )
                                        result = Result(
                                            "error",
                                            100,
                                        )
                                        break;

                                    }

                                }

                            } else {

                                result = Result(
                                    module,
                                    `${path_start}/${setting_iterator}/modules/${module_iterator}`,
                                )
                                break;

                            }

                        } else if (module_iterator === modules_length - 1) {

                            Error(
                                'modules ' + path[1],
                                100,
                            )
                            result = Result(
                                "error",
                                100,
                            )
                            break;

                        }

                    }

                } else {

                    result = Result(
                        setting,
                        `${path_start}/${setting_iterator}`,
                    )
                    break;

                }

            } else if (setting_iterator === settings_length - 1) {

                Error(
                    'modules ' + path[0],
                    100,
                )
                result = Result(
                    "error",
                    100,
                )
                break;

            }

        }

    } else {

        result = Result(
            user_settings,
            path_start,
        )

    }

    return result

}