import React, { useState } from 'react';

import { ref, set } from 'firebase/database';
import { db } from './../../client';

import { getTheme, getTranslation, getUserSettingsByRoot, hasPermission } from '../../utils';

const Select = ({ choices, user, path, reloadAfterChange }) => {
    const data = getUserSettingsByRoot(user, path)
    const [value, setValue] = useState(data.res);

    const theme = getTheme(user)

    const getLabel = value => {

        var res = null

        for (let choice of choices) {

            if (choice.value === value) res = choice.label

        }

        return res

    }

    const [label, setLabel] = useState(getLabel(data?.res));

    var timeout = setTimeout(() => timeout = null, 200)

    const onChangeHandler = e => {

        if (timeout) return

        if (!hasPermission(user, 'changeOwnSettings') && !hasPermission(user, 'changeSettings')) {
            console.error('Permission denied!\nMissing permission:', ['changeOwnSettings', 'changeSettings'])
            return
        }

        set(ref(db, data.ref), e.target.value)
        setValue(e.target.value)
        setLabel(getLabel(e.target.value))

        if (reloadAfterChange) setTimeout(() => window.location.reload(false), 200);

        timeout = setTimeout(() => timeout = null, 200)

    }

    return (
        <>
            <select
                className='px-2 w-[100px] rounded text-center cursor-pointer appearance-none border-[1px]'
                style={{
                    backgroundColor: theme.primary,
                    borderColor: theme.border,
                }}
                onChange={e => onChangeHandler(e)}
                value={value}
                title={path[2] !== 'preferredLanguage'
                    ? getTranslation(user, label)
                    : label
                }
            >
                {choices.sort((a, b) => a._index - b._index).map(choice => (

                    <option
                        value={choice.value}
                        key={choice.value}
                        title={path[2] !== 'preferredLanguage'
                            ? getTranslation(user, choice.label)
                            : choice.label
                        }
                    >
                        {path[2] !== 'preferredLanguage'
                            ? getTranslation(user, choice.label)
                            : choice.label
                        }
                    </option>

                ))}
            </select>
        </>
    );
}

export default Select;
