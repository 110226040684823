import React, { useState, useEffect } from 'react';

import { BsArrowDownCircleFill, BsArrowRightCircle } from 'react-icons/bs'

import { Loader, Select, Switch } from './extra'
import { getTheme, getTranslation, settings } from '../utils';

const _settings = settings

const Settings = ({ user, loadingUser }) => {
  const [settings, setSettings] = useState(null);
  const [open, setOpen] = useState('apperiance');

  const [height, setHeight] = useState();

  const openHandler = name => {

    if (open === name) setOpen(null)
    if (open !== name) setOpen(name)

  }

  useEffect(() => {

    let elem = document.getElementById(open)
    if (elem) {

      let elem_height = elem.scrollHeight
      setHeight(`${elem_height}px`)

    }

  });

  useEffect(() => {
    if (!loadingUser) setSettings(_settings.data)
  }, [loadingUser]);

  if (settings && !loadingUser) {
    return (
      <div
        className='flex flex-col lg:mx-20 m-5'
      >
        <div
          className='text-3xl font-bold mb-3'
        >
          {getTranslation(user, 'settings.settings')}
        </div>
        {settings.sort((a, b) => a._index - b._index).map(setting => (
          <div
            className='text-xl font-bold my-3'
            title={getTranslation(user, setting.title)}
            key={setting.name}
          >
            <div
              className='cursor-pointer flex flex-row duration-200'
              style={{
                color: open === setting.name
                  ? getTheme(user).text
                  : getTheme(user).inactive
              }}
              onClick={() => openHandler(setting.name)}
            >
              {open === setting.name ? (
                <BsArrowDownCircleFill
                  className='text-lg my-auto mr-3'
                />
              ) : (
                <BsArrowRightCircle
                  className='text-lg my-auto mr-3'
                />
              )}
              {getTranslation(user, setting.title)}
            </div>
            <div
              className='overflow-hidden duration-200 border-b-[1px] pb-1'
              style={{
                height: open === setting.name ? height : '0px',
                borderColor: getTheme(user).border
              }}
              id={setting.name}
            >
              {setting.modules.sort((a, b) => a._index - b._index).map(module => (
                <div
                  className={`mt-3 text-xl font-thin`}
                  style={{
                    color: getTheme(user).inactive
                  }}
                  title={getTranslation(user, module.title)}
                  key={module.name}
                >
                  {getTranslation(user, module.title)}
                  {module.options.sort((a, b) => a._index - b._index).map(option => (
                    <div
                      className='text-lg flex flex-row w-full relative my-1'
                      style={{
                        color: getTheme(user).border
                      }}
                      title={getTranslation(user, option.title)}
                      key={option.name}
                    >
                      • {getTranslation(user, option.title)}
                      {option._type === 'choices' && (
                        <div
                          className='flex my-auto absolute right-0'
                        >
                          <Select
                            choices={option.choices}
                            user={user}
                            path={[
                              setting.name,
                              module.name,
                              option.name,
                              true
                            ]}
                            reloadAfterChange={
                              option.name === 'preferredLanguage'
                                || option.name === 'themes' ? true : false
                            }
                          />
                        </div>
                      )}
                      {option._type === 'boolean' && (
                        <div
                          className='flex absolute right-0 my-1'
                        >
                          <Switch
                            user={user}
                            path={[
                              setting.name,
                              module.name,
                              option.name,
                              true
                            ]}
                            reloadAfterChange={
                              option.name === 'darkMode' ? true : false
                            }
                          />
                        </div>
                      )}
                      <div
                        className='w-[100px]'
                      >

                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  } else {
    return (
      <div
        className='flex justify-center items-center h-full'
      >
        <Loader size='big' user={user} />
      </div>
    )
  }
}

export default Settings;
