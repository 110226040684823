import { getUserSettingsByRoot } from "../"

export default user => {

    const language = getUserSettingsByRoot(user, [
        "apperiance",
        "text",
        "preferredLanguage",
        true,
    ])?.res || 'en'

    return language

}