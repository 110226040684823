import React, { useState } from 'react';

import { db } from '../../client';
import { set, ref } from 'firebase/database';

import { getTheme, getUserSettingsByRoot, hasPermission } from '../../utils';

const Switch = ({ user, path, reloadAfterChange }) => {
    const data = getUserSettingsByRoot(user, path)
    const [switched, setSwitched] = useState(data.res);

    const theme = getTheme(user);
    var timeout = setTimeout(() => timeout = null, 200)

    const schitchHandler = () => {

        if (timeout) return

        if (!hasPermission(user, 'changeOwnSettings') && !hasPermission(user, 'changeSettings')) {
            console.error('Permission denied!\nMissing permission:', ['changeOwnSettings', 'changeSettings'])
            return
        }

        if (switched) setSwitched(false)
        if (!switched) setSwitched(true)

        set(ref(db, data.ref), switched ? false : true);

        if (reloadAfterChange) setTimeout(() => window.location.reload(false), 200);

        timeout = setTimeout(() => timeout = null, 200)

    }

    return (
        <div
            className='flex w-[40px] h-[20px] rounded-full duration-200 cursor-pointer'
            style={{
                backgroundColor: switched
                    ? theme.interaction
                    : theme.inactive
            }}
            title={switched
                ? 'True'
                : 'False'
            }
            onClick={schitchHandler}
        >
            <div
                className={switched
                    ? 'w-[15px] h-[15px] rounded-full duration-200 my-auto ml-5 shadow-lg'
                    : 'w-[15px] h-[15px] rounded-full duration-200 my-auto ml-1 shadow-lg'
                }
                style={{
                    backgroundColor: theme.text
                }}
            >
            </div>
        </div>
    );
}

export default Switch;
