import React from 'react';

import BarLoader from 'react-spinners/BarLoader'

import logo_dark from '../../assets/logo_dark.png';
import { getTheme } from '../../utils';

const Loader = ({size, user}) => {
  const theme = getTheme(user)

  return (
    <>
      {size === 'small' ? (
        <div
          className='flex flex-col justify-center items-center w-[30px] h-[30px]'
        >
          <img
            src={logo_dark}
            className='w-[20px] mb-1'
            alt="Hanicord Logo"
          />
          <BarLoader
            className='rounded-full'
            width='30px'
            color={theme.inactive}
          />
        </div>
      ) : size === 'big' ? (
        <div
          className='flex flex-col justify-center items-center w-[70px] h-[70px]'
        >
          <img
            src={logo_dark}
            className='w-[60px] mb-1'
            alt="Hanicord Logo"
          />
          <BarLoader
            className='rounded-full'
            width='70px'
            color={theme.inactive}
          />
        </div>
      ) : (
        <div
          className='flex flex-col justify-center items-center w-[50px] h-[50px]'
        >
          <img
            src={logo_dark}
            className='w-[30px] mb-1'
            alt="Hanicord Logo"
          />
          <BarLoader
            className='rounded-full'
            width='50px'
            color={theme.inactive}
          />
        </div>
      )}
    </>
  );
}

export default Loader;
