import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ForgotPassword, Maintenance, Dashboard, Login, Home } from './container';

const App = () => {
  return (
    <Routes>
      <Route path="forgot-password" element={ <ForgotPassword /> } />
      <Route path="maintenance" element={ <Maintenance /> } />
      <Route path="dashboard" element={ <Dashboard /> } />
      <Route path="login" element={ <Login /> } />
      <Route path="/*" element={ <Home /> } />
    </Routes>
  );
}

export default App;