export default {

    primary: "#9c5016",
    secondary_darker: "#6926b5",
    secondary: "#382110",
    deactivated: "#753e0d",
    interaction: "#ff7b00",
    border: "#00ff2a",
    inactive: "#918b84",
    text: "#FAFAFA",

}