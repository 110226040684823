import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../client';

import { getGradiant, getTheme } from '../utils';

const ForgotPassword = () => {
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const [submitHovered, setSubmitHovered] = useState(false);

    const navigate = useNavigate()
    const theme = getTheme()

    const onEmailChangeHandler = e => setEmail(e.target.value)

    const sendPasswordResetHandler = e => {

        sendPasswordResetEmail(auth, email)
            .then(() => {

                setIsEmailSent(true)

            })
            .catch((error) => {
                const errorCode = error.code.replace(/auth\//, '');

                setError(errorCode)

                setTimeout(() => {
                    setError(false)
                }, 5000)
            });

        e.preventDefault()

    }

    useEffect(() => {

        onAuthStateChanged(auth, (user) => {

            if (user) {

                navigate('/')

            }

        });

    }, []);

    return (
        <div
            className='select-none flex justify-start items-center flex-col h-screen bg-gradient-to-b'
            style={{
                ...getGradiant(
                    theme.primary,
                    theme.secondary_darker,
                    0
                )
            }}
        >
            <div
                className='absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 backdrop-blur-md'
            >
                <div
                    className='px-8 py-5 border-[1px] rounded'
                    style={{
                        borderColor: theme.border
                    }}
                >
                    <form
                        className="w-[220px] flex flex-col justify-center items-center"
                        onSubmit={sendPasswordResetHandler}
                    >
                        <div className="text-slate-300 mb-3 text-lg">
                            RESET PASSWORD
                        </div>

                        {isEmailSent ? (
                            <div
                                className='flex flex-col'
                            >
                                <div
                                    className='text-lg'
                                >
                                    Email was sent!
                                </div>
                                <button
                                    className="mt-3 duration-200 rounded px-4 py-2"
                                    style={{
                                        backgroundColor: submitHovered
                                            ? theme.deactivated
                                            : theme.secondary,
                                        color: theme.text
                                    }}
                                    onMouseEnter={() => setSubmitHovered(true)}
                                    onMouseLeave={() => setSubmitHovered(false)}
                                    onClick={() => window.location.href = 'login'}
                                >
                                    Go Back
                                </button>
                            </div>
                        ) :
                        (
                            <>
                                <span
                                    className="ml-2 mb-[-5px] w-full after:content-['*'] after:ml-0.5 text-sm"
                                    style={{
                                        color: theme.inactive
                                    }}
                                >
                                    Email
                                </span>
                                <span
                                    className="ml-2 mb-[-5px] mt-1 w-full text-red-500 text-sm"
                                    style={{
                                        color: theme.deactivated
                                    }}
                                >
                                    {error === 'user-not-found' && 'Email Is Invalide!'}
                                    {error === 'missing-email' && 'Email Is Missing!'}
                                </span>
                                <input
                                    className="rounded w-full p-2 m-2 placeholder:italic border focus:outline-none focus:ring-1"
                                    style={{
                                        backgroundColor: theme.primary,
                                        borderColor: theme.border,
                                        color: theme.text
                                    }}
                                    type="email"
                                    onChange={onEmailChangeHandler}
                                    placeholder="my@mail.com"
                                />

                                <button
                                    className="duration-200 rounded px-4 py-2 mt-2"
                                    style={{
                                        backgroundColor: submitHovered
                                            ? theme.deactivated
                                            : theme.secondary,
                                        color: theme.text
                                    }}
                                    onMouseEnter={() => setSubmitHovered(true)}
                                    onMouseLeave={() => setSubmitHovered(false)}
                                    type="submit"
                                >
                                    Reset Password
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword