import { getPermissions } from "..";


/**
 * @param {object} user
 * @param {string} permission
*/
export default (user, permission) => {

    if (!user) return

    const permissions = getPermissions(user.role)

    if (permissions.includes(permission) || permissions.includes('*')) {
        return true
    } else {
        return false
    }

}