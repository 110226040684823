import { Error } from '../'

export default (primary_color, secondary_color, degrees) => {

    if (!primary_color) {
        Error(primary_color, 501)
        return {
            backgroundColor: '#04090f',
            background: `linear-gradient(0deg, #04090f 0%, #07111a 100%)`
        }
    }
    if (isNaN(degrees)) {
        Error(degrees, 500)
        return {
            backgroundColor: '#04090f',
            background: `linear-gradient(0deg, #04090f 0%, #07111a 100%)`
        }
    }
    if (!secondary_color) {
        Error(secondary_color, 502)
        return {
            backgroundColor: '#04090f',
            background: `linear-gradient(0deg, #04090f 0%, #07111a 100%)`
        }
    }

    return {
        backgroundColor: primary_color,
        background: `linear-gradient(${degrees}deg, ${primary_color} 0%, ${secondary_color} 100%)`,
    }

}