export const getDate = ( date, getTime ) => {
    let months = [
        'Jan', 
        'Feb', 
        'Mar', 
        'Apr', 
        'May', 
        'Jun', 
        'Jul', 
        'Aug', 
        'Sep', 
        'Oct', 
        'Nov', 
        'Dec'
    ];

    let month = date.substring(5, 7);
    let year = date.substring(0, 4);
    let day = date.substring(8, 10);
    let time = date.substring(11, 16);
    if (month[0] === '0') month = date.substring(6,7);

    time = getTime ?  ` - ${time}` : ''

    return `${day}. ${months[parseInt(month) + 1]} ${year}${time}`;
};

export default getDate