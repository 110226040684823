import { PSFS, toJSON } from "../";
import checkExistingPermissions from "./checkExistingPermissions";

export default function () {
    return {
        "admin": {
            title: "roles.admin",
            permissions: checkExistingPermissions('admin')
        },

        "mod": {
            title: "roles.mod",
            permissions: checkExistingPermissions('mod')
        },

        "user": {
            title: "roles.user",
            permissions: checkExistingPermissions('user')
        }
    }
}