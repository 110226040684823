import PSFS from "../PSFS"

/**
 * @param {string} role
*/
export default (role) =>{

    const permissions = PSFS.getData('role_permissions').toJSON(role)[role] || []
    const _permissions = PSFS.getData('permissions').toJSON('permissions')['permissions']

    permissions.forEach(permission => {
        if (!_permissions.includes(permission)) {
            console.warn(permission + ' doesn\'t exist');
        }
    })

    return permissions

}