export default {

    primary: "#080202",
    secondary_darker: "#1a0608",
    secondary: "#310e13",
    deactivated: "#6e0c14",
    interaction: "#a10306",
    border: "#3b2a2c",
    inactive: "#918485",
    text: "#FAFAFA",

}