import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import background from '../assets/background.mp4';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { get, child, set, ref } from 'firebase/database';
import { db, auth } from '../client';

import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs'

import { getDefaultUserSettings } from '../utils';

const Login = () => {
    const [loginForm, setLoginForm] = useState(true);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [username, setUsername] = useState(null);
    const [loggedIn, setLoggedIn] = useState(true);

    const [invalideEmail, setInvalideEmail] = useState(false);
    const [missingEmail, setMissingEmail] = useState(false);
    const [invalidePassword, setInvalidePassword] = useState(false);
    const [missingPassword, setMissingPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [invalideUsername, setInvalideUsername] = useState(false);
    const [missingUsername, setMissingUsername] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);
    const [userAlreadyExists, setUserAlreadyExists] = useState(false);

    const [viewPassword, setViewPassword] = useState(false);

    const navigate = useNavigate()

    const onEmailChange = e => setEmail(e.target.value)
    const onPasswordChange = e => setPassword(e.target.value)
    const onUsernameChange = e => setUsername(e.target.value)

    document.title = 'Hanicord • Login'

    const formSwitchHandler = () => {
        if (loginForm) {
            setViewPassword(false)
            setInvalideEmail(false)
            setMissingEmail(false)
            setInvalidePassword(false)
            setMissingPassword(false)
            setWeakPassword(false)
            setMissingUsername(false)
            setLoginForm(false)
        } else {
            setViewPassword(false)
            setInvalideEmail(false)
            setMissingEmail(false)
            setInvalidePassword(false)
            setMissingPassword(false)
            setWeakPassword(false)
            setMissingUsername(false)
            setLoginForm(true)
        }
    }

    const viewPasswordHandler = () => {
        if (viewPassword) {
            setViewPassword(false)
        } else {
            setViewPassword(true)
        }
    }

    const loginHandler = e => {

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                navigate('/')

            })
            .catch((error) => {
                const errorCode = error.code.replace(/auth\//, '');

                if (errorCode === 'invalid-email') setInvalideEmail(true)
                if (errorCode === 'missing-email') setMissingEmail(true)
                if (errorCode === 'wrong-password') setUserNotFound(true)
                if (errorCode === 'user-not-found') setUserNotFound(true)
                if (errorCode === 'internal-error') setUserNotFound(true)

                setTimeout(() => {
                    setInvalideEmail(false)
                    setMissingEmail(false)
                    setUserNotFound(false)
                }, 5000)
            });

        e.preventDefault()

    }

    const signUpHandler = e => {

        get(child(ref(db), 'users'))
            .then(snapshot => {

                if (snapshot.exists()) {

                    let data = snapshot.val()
                    var userData = null

                    for (let userId in data) {

                        if (data[userId]?.username === username) {
                            userData = data[userId]
                            setUserAlreadyExists(true)
                        }

                    }

                    if (!username?.match(/^(?=[a-zA-Z0-9._ ]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/)) setInvalideUsername(true)
                    if (!username) setMissingUsername(true)

                    setTimeout(() => {
                        setUserAlreadyExists(false)
                        setMissingUsername(false)
                        setInvalideUsername(false)
                    }, 5000)

                    if (userData?.username === username) { e.preventDefault(); return }
                    if (!username?.match(/^(?=[a-zA-Z0-9._ ]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/)) { e.preventDefault(); return }
                    if (!username) { e.preventDefault(); return }

                    createUserWithEmailAndPassword(auth, email, password)
                        .then(userCredentials => {

                            sendEmailVerification(auth.currentUser)

                            let user = userCredentials.user;

                            const image = 'https://hanicord.net/icon.png'
                            const _createdAt = new Date().toISOString()

                            set(ref(db, `users/${user.uid}`), {
                                _createdAt,
                                uid: user.uid,
                                username,
                                image,
                                role: 'user',
                                settings: getDefaultUserSettings()
                            })

                        })
                        .catch((error) => {
                            const errorCode = error.code.replace(/auth\//, '');

                            if (errorCode === 'invalid-email') setInvalideEmail(true)
                            if (errorCode === 'missing-email') setMissingEmail(true)
                            if (errorCode === 'invalide-password') setInvalidePassword(true)
                            if (errorCode === 'missing-password') setMissingPassword(true)
                            if (errorCode === 'weak-password') setWeakPassword(true)
                            if (errorCode === 'email-already-in-use') setUserAlreadyExists(true)

                            setTimeout(() => {
                                setInvalideEmail(false)
                                setMissingEmail(false)
                                setInvalidePassword(false)
                                setMissingPassword(false)
                                setWeakPassword(false)
                                setUserAlreadyExists(false)
                            }, 5000)
                        });

                } else {

                    console.error("No data available for users");

                }

            })

        e.preventDefault()

    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

                navigate('/')

            } else {

                setLoggedIn(false)

            }
        });
    }, []);

    return (
        <div className="select-none flex justify-start items-center flex-col h-screen">
            <div className="relative w-full h-full">
                <video
                    src={background}
                    type="video/mp4"
                    loop
                    controls={false}
                    muted
                    autoPlay="autoplay"
                    className="w-full h-full object-cover"
                />
            </div>

            <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
                {!loggedIn && (
                    <div className="px-8 py-5 border-sky-900 ring-1 bg-neutral-900 rounded">
                        {loginForm ? (
                            <form
                                className="w-[220px] flex flex-col justify-center items-center"
                                onSubmit={loginHandler}
                            >

                                <div className="text-slate-300 mb-3 text-lg">
                                    LOGIN
                                </div>

                                <span className="mb-1 text-red-500 text-sm">
                                    {userNotFound && 'Couldnt Find User!'}
                                </span>

                                <span className="ml-2 mb-[-5px] w-full text-slate-500 after:content-['*'] after:text-sky-600 after:ml-0.5 text-sm">
                                    Email
                                </span>
                                <span className="ml-2 mb-[-5px] mt-1 w-full text-red-500 text-sm">
                                    {invalideEmail && 'Email Is Invalide!'}
                                    {missingEmail && 'Email Is Missing!'}
                                </span>
                                <input
                                    className="rounded w-full p-2 m-2 bg-neutral-800 placeholder:italic placeholder:text-slate-400 border border-neutral-800 focus:outline-none focus:bg-neutral-800 focus:border-sky-900  focus:ring-1 text-white required:border-sky-700"
                                    type="email"
                                    onChange={onEmailChange}
                                    placeholder="my@mail.com"
                                />

                                <span className="ml-2 mb-[-5px] w-full mt-2 text-slate-500 after:content-['*'] after:text-sky-600 after:ml-0.5 text-sm">
                                    Password
                                </span>
                                <div className="w-full flex rounded m-2 bg-neutral-800">
                                    <input
                                        className="rounded w-full p-2 bg-neutral-800 placeholder:italic placeholder:text-slate-400 border border-neutral-800 focus:outline-none focus:bg-neutral-800 focus:border-sky-900  focus:ring-1 text-white required:border-sky-700"
                                        type={!viewPassword ? 'password' : 'text'}
                                        onChange={onPasswordChange}
                                        placeholder="aBc123._"
                                    />
                                    {!viewPassword ? (
                                        <BsEyeSlashFill
                                            className="m-3 cursor-pointer"
                                            onClick={viewPasswordHandler}
                                        />
                                    ) : (
                                        <BsEyeFill
                                            className="m-3 cursor-pointer"
                                            onClick={viewPasswordHandler}
                                        />
                                    )}
                                </div>

                                <div
                                    className="text-sm ml-2 w-full text-slate-600 cursor-pointer duration-200 hover:text-orange-900"
                                    onClick={() => { navigate('/forgot-password') }}
                                >
                                    • Forgot my password!
                                </div>
                                <div
                                    className="text-sm ml-2 w-full text-slate-600 cursor-pointer duration-200 hover:text-sky-900"
                                    onClick={formSwitchHandler}
                                >
                                    • Switch to Sign Up!
                                </div>

                                <button
                                    className="duration-200 rounded px-4 py-2 mt-2 hover:bg-neutral-800 text-white bg-sky-900"
                                    type="submit"
                                >
                                    Login
                                </button>

                            </form>
                        ) : (
                            <form
                                className="w-[220px] flex flex-col justify-center items-center"
                                onSubmit={signUpHandler}
                            >

                                <div className="text-slate-300 mb-3 text-lg">
                                    SIGN UP
                                </div>

                                <span className="mb-1 text-red-500 text-sm">
                                    {userAlreadyExists && 'User Already Exists!'}
                                </span>

                                <span className="ml-2 mb-[-5px] w-full text-slate-500 after:content-['*'] after:text-sky-600 after:ml-0.5 text-sm">
                                    Username
                                </span>
                                <span
                                    className="ml-2 mb-[-5px] mt-1 w-full text-red-500 text-sm"
                                    title='• Username must be between 6-20 characters long&#10;• No special charackters'
                                >
                                    {invalideUsername && 'Username Is Invalide!'}
                                    {missingUsername && 'Username Is Missing!'}
                                </span>
                                <input
                                    className="rounded w-full p-2 m-2 bg-neutral-800 placeholder:italic placeholder:text-slate-400 border border-neutral-800 focus:outline-none focus:bg-neutral-800 focus:border-sky-900  focus:ring-1 text-white required:border-sky-700"
                                    type="text"
                                    onChange={onUsernameChange}
                                    placeholder="My User Name"
                                />

                                <span className="ml-2 mb-[-5px] w-full mt-2 text-slate-500 after:content-['*'] after:text-sky-600 after:ml-0.5 text-sm">
                                    Email
                                </span>
                                <span className="ml-2 mb-[-5px] mt-1 w-full text-red-500 text-sm">
                                    {invalideEmail && 'Email Is Invalide!'}
                                    {missingEmail && 'Email Is Missing!'}
                                </span>
                                <input
                                    className="rounded w-full p-2 m-2 bg-neutral-800 placeholder:italic placeholder:text-slate-400 border border-neutral-800 focus:outline-none focus:bg-neutral-800 focus:border-sky-900  focus:ring-1 text-white required:border-sky-700"
                                    type="email"
                                    onChange={onEmailChange}
                                    placeholder="my@mail.com"
                                />

                                <span className="ml-2 mb-[-5px] w-full mt-2 text-slate-500 after:content-['*'] after:text-sky-600 after:ml-0.5 text-sm">
                                    Password
                                </span>
                                <span className="ml-2 mb-[-5px] mt-1 w-full text-red-500 text-sm">
                                    {invalidePassword && 'Password Is Invalide!'}
                                    {missingPassword && 'Password Is Missing!'}
                                    {weakPassword && 'Password Is Too Weak!'}
                                </span>
                                <div className="w-full flex rounded m-2 bg-neutral-800">
                                    <input
                                        className="rounded w-full p-2 bg-neutral-800 placeholder:italic placeholder:text-slate-400 border border-neutral-800 focus:outline-none focus:bg-neutral-800 focus:border-sky-900  focus:ring-1 text-white required:border-sky-700"
                                        type={!viewPassword ? 'password' : 'text'}
                                        onChange={onPasswordChange}
                                        placeholder="aBc123._"
                                    />
                                    {!viewPassword ? (
                                        <BsEyeSlashFill
                                            className="m-3 cursor-pointer"
                                            onClick={viewPasswordHandler}
                                        />
                                    ) : (
                                        <BsEyeFill
                                            className="m-3 cursor-pointer"
                                            onClick={viewPasswordHandler}
                                        />
                                    )}
                                </div>

                                <div
                                    className="text-sm ml-2 w-full text-slate-600 cursor-pointer duration-200 hover:text-sky-900"
                                    onClick={formSwitchHandler}
                                >
                                    • Switch to Login!
                                </div>

                                <button
                                    className="duration-200 rounded px-4 py-2 mt-2 hover:bg-neutral-800 text-white bg-sky-900"
                                    type="submit"
                                >
                                    Sign up
                                </button>

                            </form>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
