import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Sidebar, Hotbar, Hamburger, Settings } from './';

import { getTheme } from '../utils';

const Content = ({ user, loadingUser }) => {
    const [width, setWidth] = useState(window.innerWidth);

    const theme = getTheme(user);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <>
            <div
                className={width > 600 ?
                    'flex flex-col lg:w-[1050px] md:w-[700px] w-full duration-200 h-full overflow-x-hidden border-x-[1px]'
                    : 'flex flex-col lg:w-[1050px] md:w-[700px] w-full duration-200 h-full overflow-x-hidden border-x-[0px]'}
                style={{
                    borderColor: theme.border
                }}
            >
                <div
                    className='flex flex-row w-full h-full overflow-y-scroll scrollbar-hide'
                >
                    {width > 600 && (
                        <Sidebar user={user} loadingUser={loadingUser} />
                    )}
                    <div
                        className='flex flex-col w-full overflow-y-scroll scrollbar-hide'
                    >
                        <Hamburger user={user} loadingUser={loadingUser} />
                        <div
                            className='flex flex-col p-5 pb-0 overflow-y-scroll scrollbar-hide'
                        >
                            <Routes>
                                <Route path="/settings" element={<Settings user={user} loadingUser={loadingUser} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
                {width < 600 && (
                    <Hotbar user={user} loadingUser={loadingUser} />
                )}
            </div>
        </>
    );
}

export default Content;

