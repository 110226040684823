import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import logo_dark from '../assets/logo_dark.png';

import { MdAddModerator, MdAdminPanelSettings, MdOutlineSpaceDashboard } from 'react-icons/md'
import { HiHome, HiOutlineNewspaper } from 'react-icons/hi';
import { IoIosNotifications } from 'react-icons/io'
import { BiLogIn, BiLogOut } from 'react-icons/bi'
import { FiSettings } from 'react-icons/fi';

import { get, child, ref } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth, db } from '../client';

import { Loader } from './extra'
import { getTheme, getTranslation, getVersion, getDate, getTags, getGradiant, getRoleData } from '../utils';

const Sidebar = ({ user, loadingUser }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [version, setVersion] = useState(null);
    const [version_mode, setVersionMode] = useState(null);
    const [expanded, setExpanded] = useState(false);
    
    const [dashboardHovered, setDashboardHovered] = useState(false);
    const [settingsHovered, setSettingsHovered] = useState(false);
    const [logoutHovered, setLogoutHovered] = useState(false);
    const [loginHovered, setLoginHovered] = useState(false);
    
    const theme = getTheme(user)
    const navigate = useNavigate()
    const param = useParams()['*']

    const [selectedPage, setSelectedPage] = useState(param || 'home');

    const [tags, setTags] = useState(null);

    const expandHandler = () => {

        if (width < 1024) setExpanded(expanded ? false : true)
        if (width > 1024) navigate('/')

    }

    const logoutHandler = () => {

        signOut(auth).then(() => {

            localStorage.setItem('theme', 'blue')
            localStorage.setItem('darkMode', true)
            navigate('login')

        }).catch((error) => {
            console.error(error)
        });

    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        getTags(tags => setTags(tags))
        get(child(ref(db), 'system'))
            .then(snapshot => {

                const data = snapshot.val()

                if (snapshot.exists()) {

                    setVersion(data.version)
                    setVersionMode(data.version_mode)

                } else {

                    console.error('Version snapshot doesnt exist')

                }

            })

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let once = 0
    useEffect(() => {
        if (selectedPage === 'home') {
            navigate('.')
        } else {
            if (selectedPage === 'news') return navigate('news')
            if (selectedPage === 'notifications') return navigate('notifications')
            if (selectedPage === 'settings') return navigate('settings')
            navigate(`tag/${selectedPage}`)
        }
    }, [selectedPage]);

    return (
        <div
            className={`flex flex-col lg:w-[250px] ${expanded ? 'w-[250px]' : 'w-[70px]'} duration-200 h-full border-r-[1px]`}
            style={{
                borderColor: theme.border
            }}
        >
            {width > 1024 || width > 600 && expanded ? (
                <>
                    <div
                        className='flex flex-col w-full h-[90px] border-b-[1px] overflow-x-hidden'
                        style={{
                            borderColor: theme.border
                        }}
                    >
                        <div
                            className='flex flex-col h-full justify-center items-center m-3'
                        >
                            <div
                                className='flex flex-row cursor-pointer'
                                title='Hanicord'
                                onClick={expandHandler}
                            >
                                <img
                                    src={logo_dark}
                                    className='w-[50px]'
                                />

                                <div
                                    className='flex flex-col justify-center mx-2'
                                >
                                    <div
                                        className='font-bold text-xl'
                                    >
                                        Hanicord
                                    </div>
                                    <div
                                        className='text-sm'
                                        style={{
                                            color: theme.border
                                        }}
                                    >
                                        {getVersion(version, version_mode) || 'Version unavailable'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='flex flex-col mt-3 overflow-x-hidden'
                    >
                        <div
                            className='pl-3 p-1 my-1 text-lg duration-200 cursor-pointer flex flex-row'
                            style={{
                                color: selectedPage === 'home'
                                    ? theme.interaction
                                    : theme.border,
                                borderColor: selectedPage === 'home'
                                    ? theme.interaction
                                    : theme.border,
                                borderLeftWidth: selectedPage === 'home'
                                    ? '2px'
                                    : '0px',
                                background: selectedPage === 'home'
                                    ? getGradiant(
                                        theme.deactivated + '55',
                                        '#00000000',
                                        90
                                    ).background
                                    : '#00000000',
                            }}
                            onClick={() => setSelectedPage('home')}
                            title={getTranslation(user, 'home.home')}
                        >
                            <HiHome
                                className='my-auto mr-1'
                            />
                            {getTranslation(user, 'home.home')}
                        </div>
                        <div
                            className='pl-3 p-1 my-1 text-lg duration-200 cursor-pointer flex flex-row'
                            style={{
                                color: selectedPage === 'news'
                                    ? theme.interaction
                                    : theme.border,
                                borderColor: selectedPage === 'news'
                                    ? theme.interaction
                                    : theme.border,
                                borderLeftWidth: selectedPage === 'news'
                                    ? '2px'
                                    : '0px',
                                background: selectedPage === 'news'
                                    ? getGradiant(
                                        theme.deactivated + '55',
                                        '#00000000',
                                        90
                                    ).background
                                    : '#00000000',
                            }}
                            onClick={() => setSelectedPage('news')}
                            title={getTranslation(user, 'home.news')}
                        >
                            <HiOutlineNewspaper
                                className='my-auto mr-1'
                            />
                            {getTranslation(user, 'home.news')}
                        </div>
                        <div
                            className='pl-3 p-1 my-1 text-lg duration-200 cursor-pointer flex flex-row'
                            style={{
                                color: selectedPage === 'notifications'
                                    ? theme.interaction
                                    : theme.border,
                                borderColor: selectedPage === 'notifications'
                                    ? theme.interaction
                                    : theme.border,
                                borderLeftWidth: selectedPage === 'notifications'
                                    ? '2px'
                                    : '0px',
                                background: selectedPage === 'notifications'
                                    ? getGradiant(
                                        theme.deactivated + '55',
                                        '#00000000',
                                        90
                                    ).background
                                    : '#00000000',
                            }}
                            onClick={() => setSelectedPage('notifications')}
                            title={getTranslation(user, 'home.notifications')}
                        >
                            <IoIosNotifications
                                className='my-auto mr-1'
                            />
                            {getTranslation(user, 'home.notifications')}
                        </div>
                        {tags?.sort((a, b) => {
                            a = (a.stats.likes + a.stats.views / 100)
                            b = (b.stats.likes + b.stats.views / 100)

                            return b - a
                        }).map(tag => (
                            <div
                                className='pl-3 p-1 my-1 text-lg duration-200 cursor-pointer flex flex-row'
                                style={{
                                    color: selectedPage === tag.title.toLowerCase()
                                        ? theme.interaction
                                        : theme.border,
                                    borderColor: selectedPage === tag.title.toLowerCase()
                                        ? theme.interaction
                                        : theme.border,
                                    borderLeftWidth: selectedPage === tag.title.toLowerCase()
                                        ? '2px'
                                        : '0px',
                                    background: selectedPage === tag.title.toLowerCase()
                                        ? getGradiant(
                                            theme.deactivated + '55',
                                            '#00000000',
                                            90
                                        ).background
                                        : '#00000000',
                                }}
                                onClick={() => setSelectedPage(tag.title.toLowerCase())}
                                title={tag.title}
                                key={tag.title}
                            >

                                {tag.title}
                            </div>
                        ))}
                    </div>
                </>
            ) : width > 600 ? (

                <div
                    className='flex flex-col justify-center items-center w-full h-[70px] border-b-[1px]'
                    style={{
                        borderColor: theme.border
                    }}
                >
                    <div
                        className='m-3 cursor-pointer'
                        title='Hanicord'
                        onClick={expandHandler}
                    >
                        <img
                            src={logo_dark}
                            className='w-[40px]'
                            alt="Hanicord Logo"
                        />
                    </div>
                </div>

            ) : ''}
            {loadingUser ? (
                <div
                    className='flex mt-auto justify-center items-center w-full mb-5'
                >
                    <Loader user={user} />
                </div>
            ) : (
                <>
                    {user ? (

                        <div
                            className='flex mt-auto cursor-pointer w-full overflow-x-hidden'
                        >
                            <div
                                className='p-3 w-full border-t-[1px]'
                                style={{
                                    borderColor: theme.border,
                                }}
                            >
                                <Link
                                    to={`/user/${user.username?.simplify()}`}
                                    className='flex flex-row'
                                    title={user.username}
                                >
                                    <img
                                        className='w-[50px] rounded-full border-[1px]'
                                        style={{
                                            borderColor: theme.border,
                                            backgroundColor: theme.secondary,
                                        }}
                                        src={user.image}
                                    />

                                    {width > 1024 || width > 600 && expanded ? (
                                        <div
                                            className='flex flex-col justify-center ml-2'
                                        >
                                            <div
                                                className='flex flex-row font-semibold text-lg'
                                            >
                                                <div
                                                    className='my-auto mr-1'
                                                    title={getTranslation(user, getRoleData(user.role).title)}
                                                >
                                                    {
                                                        user.role === 'admin' 
                                                            ? (
                                                                <MdAdminPanelSettings />
                                                            ) : user.role === 'mod' 
                                                            ? (
                                                                <MdAddModerator />
                                                            ) : 
                                                        (<></>)
                                                    }
                                                </div>
                                                {user.username}
                                            </div>
                                            <div
                                                className='text-xs'
                                                style={{
                                                    color: theme.border
                                                }}
                                            >
                                                {getDate(user._createdAt)}
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Link>

                                <div
                                    className='flex flex-col mt-3'
                                >
                                    <div
                                        className='flex flex-row duration-200'
                                        style={{
                                            color: dashboardHovered
                                                ? theme.interaction
                                                : theme.border,
                                        }}
                                        id="dashboard"
                                        onMouseEnter={() => setDashboardHovered(true)}
                                        onMouseLeave={() => setDashboardHovered(false)}
                                        title={getTranslation(user, 'dashboard.dashboard')}
                                    >
                                        {width > 1024 || width > 600 && expanded ? (
                                            <Link
                                                to={'dashboard'}
                                                className='flex flex-row'
                                            >
                                                <MdOutlineSpaceDashboard
                                                    className='text-sm m-1'
                                                />
                                                {getTranslation(user, 'dashboard.dashboard')}
                                            </Link>
                                        ) : (
                                            <Link
                                                to={'dashboard'}
                                                className='flex flex-col justify-center items-center w-full m-1'
                                            >
                                                <MdOutlineSpaceDashboard
                                                    className='text-[20px]'
                                                />
                                            </Link>
                                        )}
                                    </div>
                                    <div
                                        className='flex flex-row duration-200'
                                        style={{
                                            color: settingsHovered
                                                ? theme.interaction
                                                : theme.border,
                                        }}
                                        id="settings"
                                        onMouseEnter={() => setSettingsHovered(true)}
                                        onMouseLeave={() => setSettingsHovered(false)}
                                        title={getTranslation(user, 'settings.settings')}
                                    >
                                        {width > 1024 || width > 600 && expanded ? (
                                            <Link
                                                to={'settings'}
                                                className='flex flex-row'
                                            >
                                                <FiSettings
                                                    className='text-sm m-1'
                                                />
                                                {getTranslation(user, 'settings.settings')}
                                            </Link>
                                        ) : (
                                            <Link
                                                to={'settings'}
                                                className='flex flex-col justify-center items-center w-full m-1'
                                            >
                                                <FiSettings
                                                    className='text-[20px]'
                                                />
                                            </Link>
                                        )}
                                    </div>
                                    <div
                                        className='flex flex-row duration-200'
                                        style={{
                                            color: logoutHovered
                                                ? theme.interaction
                                                : theme.border,
                                        }}
                                        id="logout"
                                        onMouseEnter={() => setLogoutHovered(true)}
                                        onMouseLeave={() => setLogoutHovered(false)}
                                        onClick={logoutHandler}
                                        title={getTranslation(user, 'home.logout')}
                                    >

                                        {width > 1024 || width > 600 && expanded ? (
                                            <>
                                                <BiLogOut
                                                    className='text-sm m-1'
                                                />
                                                {getTranslation(user, 'home.logout')}
                                            </>
                                        ) : (
                                            <div
                                                className='flex flex-col justify-center items-center w-full m-1'
                                            >
                                                <BiLogOut
                                                    className='text-[20px]'
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (

                        <div
                            className='mb-5 flex mt-auto cursor-pointer w-full justify-center items-center'
                        >
                            {width > 1024 || width > 600 && expanded ? (
                                <Link
                                    to={`login`}
                                    className="flex my-5 mb-3 gap-2 p-2 mx-3 justify-center items-center"
                                    title={getTranslation(user, 'home.login')}
                                >
                                    <div
                                        className="py-1 px-3 animate-bounce rounded duration-200"
                                        style={{
                                            color: theme.text,
                                            backgroundColor: loginHovered
                                                ? theme.interaction
                                                : theme.deactivated
                                        }}
                                        onMouseEnter={() => setLoginHovered(true)}
                                        onMouseLeave={() => setLoginHovered(false)}
                                    >
                                        {getTranslation(user, 'home.login')}
                                    </div>
                                </Link>
                            ) : (
                                <Link
                                    to={`login`}
                                    className="flex my-5 mb-3 gap-2 p-2 mx-3 justify-center items-center duration-200"
                                    style={{
                                        color: loginHovered
                                            ? theme.interaction
                                            : theme.deactivated
                                    }}
                                    onMouseEnter={() => setLoginHovered(true)}
                                    onMouseLeave={() => setLoginHovered(false)}
                                    title={getTranslation(user, 'home.login')}
                                >
                                    <BiLogIn
                                        className='text-[20px]'
                                    />
                                </Link>
                            )}
                        </div>

                    )}
                </>
            )}
        </div>
    );
}

export default Sidebar;
