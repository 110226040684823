import { get, child, ref } from 'firebase/database';
import { db } from '../../client';

import { hasPermission } from '../';
import checkExistingPermissions from '../roles/checkExistingPermissions';

export default async (user) => {

    await get(child(ref(db), 'system'))
        .then(snapshot => {

            if (snapshot.exists()) {

                const data = snapshot.val()

                if (data.isMaintenance) {

                    if (!data.whitelistedIds.includes(user.uid) || !hasPermission(user, 'accessMaintenance')) window.location.href = 'maintenance'

                }

            }

        })

    return true

}