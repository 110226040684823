import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { BiMessageDetail, BiNews } from 'react-icons/bi';
import { MdNotificationsNone } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai'
import { FiSettings } from 'react-icons/fi';

import { simplify, getTranslation, getTheme } from '../utils'

const Hotbar = ({ user, loadingUser }) => {
    const [selected, setSelected] = useState("home");

    const [newsHovered, setNewsHovered] = useState(false);
    const [messagesHovered, setMessagesHovered] = useState(false);
    const [homeHovered, setHomeHovered] = useState(false);
    const [notifyHovered, setNotifyHovered] = useState(false);
    const [settingsHovered, setSettingsHovered] = useState(false);

    const theme = getTheme(user);
    const param = useParams()

    useEffect(() => {

        setSelected(param['*'] ||= 'home')

    }, [param]);

    return (
        <div
            className='flex flex-col mt-auto w-full h-[60px] border-t-[1px]'
            style={{
                borderColor: theme.border
            }}
        >
            <div
                className='flex justify-center items-center h-full my-3'
            >
                <div
                    className='flex flex-row justify-around w-full'
                >
                    <Link
                        to={'news'}
                        className="flex flex-auto justify-center items-center text-[25px]"
                        title={getTranslation(user, 'home.news')}
                    >
                        <BiNews
                            className='w-full duration-200'
                            style={{
                                color: selected === "news"
                                    ? newsHovered
                                        ? theme.interaction
                                        : theme.deactivated
                                    : newsHovered
                                        ? theme.deactivated
                                        : theme.border
                            }}
                            onMouseEnter={() => setNewsHovered(true)}
                            onMouseLeave={() => setNewsHovered(false)}
                        />
                    </Link>
                    {user && (
                        <Link
                            to={'messages'}
                            className="flex flex-auto justify-center items-center text-[25px]"
                            title={getTranslation(user, 'home.messages')}
                        >
                            <BiMessageDetail
                                className='w-full duration-200'
                                style={{
                                    color: selected === "messages"
                                        ? messagesHovered
                                            ? theme.interaction
                                            : theme.deactivated
                                        : messagesHovered
                                            ? theme.deactivated
                                            : theme.border
                                }}
                                onMouseEnter={() => setMessagesHovered(true)}
                                onMouseLeave={() => setMessagesHovered(false)}
                            />
                        </Link>
                    )}
                    <Link
                        to={'/'}
                        className="flex flex-auto justify-center items-center text-[25px]"
                        title={getTranslation(user, 'home.home')}
                    >
                        <AiOutlineHome
                            className='w-full duration-200'
                            style={{
                                color: selected === "home"
                                    ? homeHovered
                                        ? theme.interaction
                                        : theme.deactivated
                                    : homeHovered
                                        ? theme.deactivated
                                        : theme.border
                            }}
                            onMouseEnter={() => setHomeHovered(true)}
                            onMouseLeave={() => setHomeHovered(false)}
                        />
                    </Link>
                    <Link
                        to={'notificatons'}
                        className="flex flex-auto justify-center items-center text-[25px]"
                        title={getTranslation(user, 'home.notifications')}
                    >
                        <MdNotificationsNone
                            className='w-full duration-200'
                            style={{
                                color: selected === "notify"
                                    ? notifyHovered
                                        ? theme.interaction
                                        : theme.deactivated
                                    : notifyHovered
                                        ? theme.deactivated
                                        : theme.border
                            }}
                            onMouseEnter={() => setNotifyHovered(true)}
                            onMouseLeave={() => setNotifyHovered(false)}
                        />
                    </Link>
                    {user && (
                        <Link
                            to={'settings'}
                            className="flex flex-auto justify-center items-center text-[25px]"
                            title={getTranslation(user, 'settings.settings')}
                        >
                            <FiSettings
                                className='w-full duration-200'
                                style={{
                                    color: selected === "settings"
                                        ? settingsHovered
                                            ? theme.interaction
                                            : theme.deactivated
                                        : settingsHovered
                                            ? theme.deactivated
                                            : theme.border
                                }}
                                onMouseEnter={() => setSettingsHovered(true)}
                                onMouseLeave={() => setSettingsHovered(false)}
                            />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Hotbar;
