import { languages, settingsVersion } from "../";

export default {

    _version: settingsVersion,

    data: [

        {

            _index: 0,
            name: 'apperiance',
            title: 'settings.apperiance',

            modules: [

                {

                    _index: 0,
                    name: 'display',
                    title: 'settings.display',

                    options: [

                        {

                            _index: 0,
                            _type: 'boolean',
                            name: 'darkMode',
                            title: 'settings.darkMode',

                            default_value: true,

                        },
                        {

                            _index: 1,
                            _type: 'choices',
                            name: 'themes',
                            title: 'settings.themes',

                            choices: [

                                {

                                    _index: 0,
                                    label: 'choices.themeBlue',
                                    value: 'blue',
                                    icon: '',

                                },
                                {

                                    _index: 1,
                                    label: 'choices.themePurple',
                                    value: 'purple',
                                    icon: '',

                                },
                                {

                                    _index: 2,
                                    label: 'choices.themePink',
                                    value: 'pink',
                                    icon: '',

                                },
                                {

                                    _index: 3,
                                    label: 'choices.themeGreen',
                                    value: 'green',
                                    icon: '',

                                },
                                {
                                    
                                    _index: 4,
                                    label: 'choices.themeRed',
                                    value: 'red',
                                    icon: '',
                                    
                                },
                                {

                                    _index: 5,
                                    label: 'choices.themeInstagram',
                                    value: 'instagram',
                                    icon: '',

                                },

                            ],

                            default_value: 'blue',

                        },

                    ],

                },
                {

                    _index: 1,
                    name: 'text',
                    title: 'settings.text',

                    options: [

                        {

                            _index: 0,
                            _type: 'choices',
                            name: 'preferredLanguage',
                            title: 'settings.preferredLanguage',

                            choices: Object.keys(languages).map((language, _index) => {

                                return {

                                    _index,
                                    label: languages[language].name,
                                    value: language,
                                    icon: languages[language].icon

                                }
                                    
                            }),

                            default_value: 'en',

                        },

                    ],

                },

            ],

        },
        {

            _index: 0,
            name: 'privacy',
            title: 'settings.privacy',

            modules: [

                {

                    _index: 0,
                    name: 'messages',
                    title: 'settings.messages',

                    options: [
                        
                        {

                            _index: 1,
                            _type: 'choices',
                            name: 'allowDirectMessages',
                            title: 'settings.allowDirectMessages',

                            choices: [

                                {

                                    _index: 0,
                                    label: 'choices.all',
                                    value: 'all',
                                    icon: '',

                                },
                                {

                                    _index: 1,
                                    label: 'choices.friends',
                                    value: 'friends',
                                    icon: '',

                                },
                                {

                                    _index: 2,
                                    label: 'choices.none',
                                    value: 'none',
                                    icon: '',

                                },

                            ],

                            default_value: 'all',

                        },

                    ],

                },

            ],

        },

    ],

}