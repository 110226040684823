export default (version, version_mode) => {

    const vm = version_mode === '1' ? 'beta.' : version_mode === '2' ? '' : 'alpha.'
    // eslint-disable-next-line
    const v = eval(version / 1000)
    const v_ = v | 0
    // eslint-disable-next-line
    const vh = eval((v - v_) * 100)
    const vh_ = vh | 0
    const vt = (vh * 10 - `${vh_}0`)
                            
    const version_str = `${vm}v.${v_}.${vh_ < 10 ? `0${vh_}` : vh_}.${vt.toFixed(0)}`
                            
    return version_str

}