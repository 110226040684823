import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "hanicord-2.firebaseapp.com",
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: "hanicord-2",
  storageBucket: "hanicord-2.appspot.com",
  messagingSenderId: "673803047760",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-WCJGZW9WJ3"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);