import React, { useState, useEffect } from 'react';

import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { get, child, ref } from 'firebase/database';
import { db, auth } from '../client';

import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs'

import { Loader } from '../components/extra/';
import { getGradiant, getRoleData, getTheme, getTranslation, hasPermission } from '../utils';
import { MdAddModerator, MdAdminPanelSettings } from 'react-icons/md';

const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [accessKey, setAccessKey] = useState(null);

    const [gradiant, setGradiant] = useState(getGradiant(
        getTheme().primary,
        getTheme().secondary_darker,
        0
    ));
    const [theme, setTheme] = useState(getTheme());

    const [roleName, setRoleName] = useState(null);

    const [error, setError] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const [logoutHovered, setLogoutHovered] = useState(false);
    const [loginHovered, setLoginHovered] = useState(false);

    const onEmailChange = e => setEmail(e.target.value)
    const onPasswordChange = e => setPassword(e.target.value)
    const onAccessKeyChange = e => setAccessKey(e.target.value)

    document.title = 'Hanicord • Access Dashboard'

    const viewPasswordHandler = () => {
        if (viewPassword) {
            setViewPassword(false)
        } else {
            setViewPassword(true)
        }
    }

    const logoutHandler = () => {

        signOut(auth).then(() => {

            localStorage.setItem('theme', 'blue')
            localStorage.setItem('darkMode', true)
            window.location.reload(false)

        }).catch((error) => {

            console.error(error)

        });

    }

    const loginHandler = e => {

        if (error === 'user-no-access') { e.preventDefault(); return }

        if (user) {
            if (accessKey !== process.env.REACT_APP_DASHBOARD_ACCESS_KEY) setError('invalide-access-key')
            if (!accessKey) setError('missing-access-key')

            setTimeout(() => {
                setError(null)
            }, 5000)

            if (!accessKey) {
                setTimeout(() => {
                    setError(null)
                }, 5000)
                e.preventDefault();
                return
            }
            if (accessKey !== process.env.REACT_APP_DASHBOARD_ACCESS_KEY) {
                setTimeout(() => {
                    setError(null)
                }, 5000)
                e.preventDefault();
                return
            }
        }

        if (!user) signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                const user = userCredential.user

                get(child(ref(db), 'users'))
                    .then(snapshot => {

                        if (snapshot.exists()) {

                            const data = snapshot.val()
                            let userData = data[user.uid];

                            if (hasPermission(userData, 'accessDashboard')) {

                                if (accessKey !== process.env.REACT_APP_DASHBOARD_ACCESS_KEY) setError('invalide-access-key')
                                if (!accessKey) setError('missing-access-key')

                                setTimeout(() => {
                                    setError(null)
                                }, 5000)

                                if (!accessKey) {
                                    setTimeout(() => {
                                        setError(null)
                                    }, 5000)
                                    e.preventDefault();
                                    return
                                }
                                if (accessKey !== process.env.REACT_APP_DASHBOARD_ACCESS_KEY) {
                                    setTimeout(() => {
                                        setError(null)
                                    }, 5000)
                                    e.preventDefault();
                                    return
                                }

                                window.location.href = `https://hanicord-dashboard.netlify.app/auth/${user.uid}`

                                e.preventDefault()
                                return

                            } else {
                                setError('user-no-access')

                                e.preventDefault()
                                return
                            }

                        } else {

                            console.error('Couldnt find user snapshot')

                        }

                    })

            })
            .catch((error) => {
                const errorCode = error.code.replace(/auth\//, '');

                setError(errorCode)

                setTimeout(() => {
                    setError(null)
                }, 5000)
            });


        if (user && hasPermission(user, 'accessDashboard')) window.location.href = `https://hanicord-dashboard.netlify.app/auth/${user.uid}`

        e.preventDefault()

    }

    useEffect(() => {

        onAuthStateChanged(auth, user => {

            if (user) {

                get(child(ref(db), 'users'))
                    .then(snapshot => {

                        if (snapshot.exists()) {

                            const data = snapshot.val()
                            let userData = data[user.uid];

                            setUser(userData)
                            setTheme(getTheme(userData))
                            setGradiant(getGradiant(
                                getTheme(userData).primary,
                                getTheme(userData).secondary_darker,
                                0
                            ))

                            setLoadingUser(false)
                            setRoleName(getTranslation(userData, getRoleData(userData.role).title))

                            if (!hasPermission(userData, 'accessDashboard')) setError('user-no-access')

                        } else {

                            console.error('Couldnt find user snapshot')

                        }

                    })

            } else {

                setLoadingUser(false)

            }

        });

    }, []);

    return (
        <div
            className="select-none flex justify-start items-center flex-col h-screen"
            style={{
                ...gradiant
            }}
        >
            <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 backdrop-blur-md">
                <div
                    className="px-8 py-5 border-[1px] rounded"
                    style={{
                        borderColor: theme.border
                    }}
                >
                    <form
                        className="w-[220px] flex flex-col justify-center items-center"
                        onSubmit={loginHandler}
                    >
                        <div className="text-slate-300 mb-3 text-lg">
                            {getTranslation(user, 'dashboard.dashboard').toUpperCase()}
                        </div>

                        <span
                            className="mb-1 text-sm"
                            style={{
                                color: theme.deactivated
                            }}
                        >
                            {error === 'user-no-access' && 'User Has No Access!'}
                        </span>

                        {loadingUser ? (
                            <div
                                className='flex justify-center items-center h-full'
                            >
                                <Loader size='big' user={user} />
                            </div>
                        ) : (
                            <>
                                {user ? (
                                    <div
                                        className="flex flex-col w-full h-[90px] rounded-md border-[1px] cursor-default"
                                        style={{
                                            backgroundColor: theme.primary,
                                            borderColor: theme.border
                                        }}
                                    >
                                        <div
                                            className='flex flex-row w-full h-full'
                                        >
                                            <div
                                                className="flex w-[70px] h-full"
                                            >
                                                <img
                                                    className='w-[50px] h-[50px] m-auto rounded-full border-[1px]'
                                                    style={{
                                                        borderColor: theme.border
                                                    }}
                                                    src={user.image}
                                                    alt="profile"
                                                />
                                            </div>
                                            <div
                                                className='flex flex-col h-full'
                                            >
                                                <div
                                                    className='my-auto'
                                                >
                                                    <div
                                                        className='font-bold'
                                                    >
                                                        {user.username}
                                                    </div>
                                                    <div
                                                        className='text-sm flex flex-row'
                                                        style={{
                                                            color: theme.interaction
                                                        }}
                                                        title={roleName}
                                                    >
                                                        <div
                                                            className='my-auto mr-1'
                                                        >
                                                            {user.role === 'admin' ? (
                                                                <MdAdminPanelSettings />
                                                            ) : user.role === 'mod' ? (
                                                                <MdAddModerator />
                                                            ) : (<></>)}
                                                        </div>
                                                        {roleName}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='flex text-xs mx-3 mb-1 duration-200 cursor-pointer'
                                            style={{
                                                color: logoutHovered
                                                    ? theme.interaction
                                                    : theme.deactivated
                                            }}
                                            onMouseEnter={() => setLogoutHovered(true)}
                                            onMouseLeave={() => setLogoutHovered(false)}
                                            onClick={logoutHandler}
                                        >
                                            • {getTranslation(user, 'dashboard.switchAccount')}
                                        </div>
                                    </div>
                                ) : (
                                    <>

                                        <span
                                            className="mb-1 text-sm"
                                            style={{
                                                color: theme.deactivated
                                            }}
                                        >
                                            {error === 'user-not-found' && getTranslation(user, `login[${error}]`)}
                                        </span>

                                        <span
                                            className="ml-2 mb-[-5px] w-full after:content-['*'] after:ml-0.5 text-sm"
                                            style={{
                                                color: theme.inactive,
                                            }}
                                        >
                                            {getTranslation(user, 'login.email')}
                                        </span>
                                        <span
                                            className="ml-2 mb-[-5px] mt-1 w-full text-sm"
                                            style={{
                                                color: theme.deactivated
                                            }}
                                        >
                                            {error === 'invalid-email' && getTranslation(user, `login['${error}']`)}
                                            {error === 'missing-email' && getTranslation(user, `login['${error}']`)}
                                        </span>
                                        <input
                                            className="rounded w-full p-2 m-2 placeholder:italic border focus:outline-none focus:ring-1"
                                            style={{
                                                backgroundColor: theme.primary,
                                                border: theme.border,
                                                color: theme.inactive
                                            }}
                                            type="email"
                                            onChange={onEmailChange}
                                            placeholder={getTranslation(user, 'login.emailPlaceholder')}
                                        />

                                        <span
                                            className="ml-2 mb-[-5px] w-full mt-2 after:content-['*'] after:ml-0.5 text-sm"
                                            style={{
                                                color: theme.inactive
                                            }}
                                        >
                                            {getTranslation(user, 'login.password')}
                                        </span>
                                        <div
                                            className="w-full flex rounded m-2"
                                            style={{
                                                backgroundColor: theme.primary,
                                            }}
                                        >
                                            <input
                                                className="rounded w-full p-2 placeholder:italic border focus:outline-none focus:ring-1"
                                                style={{
                                                    backgroundColor: theme.primary,
                                                    border: theme.border,
                                                    color: theme.inactive
                                                }}
                                                type={!viewPassword ? 'password' : 'text'}
                                                onChange={onPasswordChange}
                                                placeholder={getTranslation(user, 'login.passwordPlaceholder')}
                                            />
                                            {!viewPassword ? (
                                                <BsEyeSlashFill
                                                    className="m-3 cursor-pointer duration-200"
                                                    style={{
                                                        color: theme.inactive
                                                    }}
                                                    onClick={viewPasswordHandler}
                                                />
                                            ) : (
                                                <BsEyeFill
                                                    className="m-3 cursor-pointer duration-200"
                                                    style={{
                                                        color: theme.text
                                                    }}
                                                    onClick={viewPasswordHandler}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        <span
                            className="ml-2 mb-[-5px] w-full mt-2 after:content-['*'] after:ml-0.5 text-sm"
                            style={{
                                color: theme.inactive
                            }}
                        >
                            {getTranslation(user, 'dashboard.accessKey')}
                        </span>

                        <span
                            className="ml-2 mb-[-5px] mt-1 w-full text-sm"
                            style={{
                                color: theme.deactivated
                            }}
                        >
                            {error === 'missing-access-key' && getTranslation(user, `dashboard['${error}']`)}
                            {error === 'invalide-access-key' && getTranslation(user, `dashboard['${error}']`)}
                        </span>

                        <div className="w-full flex rounded m-2">
                            <input
                                className="rounded w-full p-2 placeholder:italic border focus:outline-none focus:ring-1"
                                style={{
                                    backgroundColor: theme.primary,
                                    border: theme.border,
                                    color: theme.inactive
                                }}
                                type='text'
                                onChange={onAccessKeyChange}
                                placeholder={getTranslation(user, 'dashboard.accessKey')}
                            />
                        </div>

                        <button
                            className="duration-200 rounded px-4 py-1 mt-2"
                            style={{
                                backgroundColor: loginHovered
                                    ? theme.deactivated
                                    : theme.secondary,
                                color: theme.text
                            }}
                            onMouseEnter={() => setLoginHovered(true)}
                            onMouseLeave={() => setLoginHovered(false)}
                            type="submit"
                            title={getTranslation(user, 'login.login')}
                        >
                            {getTranslation(user, 'login.login')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
