export default {

    primary: "#04090f",
    secondary_darker: "#07111a",  
    secondary: "#0e2131",
    deactivated: "#0c4a6e",
    interaction: "#0369a1",
    border: "#2a303b",
    inactive: "#848891",
    text: "#FAFAFA",

}