export default {

    primary: "#050208",
    secondary_darker: "#0f061a",
    secondary: "#1d0e31",
    deactivated: "#3a0c6e",
    interaction: "#5a03a1",
    border: "#312a3b",
    inactive: "#898491",
    text: "#FAFAFA",

}