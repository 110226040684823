import { child, get, ref } from "firebase/database"
import { db } from "../../client"

export default (callback) => {

    get(child(ref(db), 'tags'))
        .then(snapshot => {

            if (snapshot.exists) {

                const data = snapshot.val()

                callback(data)

            }

        })

}