import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { child, get, ref } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../client';

import { Content, Ads } from '../components/';
import { Loader } from './../components/extra';

import { getTheme, getGradiant, isMaintenance } from '../utils';

const Home = () => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [connectionLost, setConnectionLost] = useState(false);

  const [retryHovered, setRetryHovered] = useState(false);

  const theme = getTheme(user)
  const param = useParams()['*']

  useEffect(() => {

    if (param === '') document.title = 'Hanicord'
    if (param === 'settings') document.title = 'Hanicord • Settings'
    if (param === 'notifications') document.title = 'Hanicord • Notifications'
    if (param === 'news') document.title = 'Hanicord • News'
    if (param === 'messages') document.title = 'Hanicord • Messages'
    if (param.match('tag')) document.title = 'Hanicord • ' + param.replace(/tag\//, "")[0].toUpperCase() + param.replace(/tag\//, "").slice(1, param.length - 1)

    onAuthStateChanged(auth, async user => {

      if (user) {

        get(child(ref(db), 'users'))
          .then(async snapshot => {

            if (snapshot.exists()) {

              const data = snapshot.val()
              let userData = data[user.uid];

              await isMaintenance(userData)
              setUser(userData)
              setLoadingUser(false)

            } else {

              console.error('Couldnt find user snapshot')

            }

          })

      } else {

        await isMaintenance('0')
        setLoadingUser(false)

      }

    });

    setTimeout(() => {
      if (loadingUser) setConnectionLost(true)
    }, 5000)

  }, [param]);

  return (
    <div
      className='select-none flex justify-center items-center flex-row h-screen'
      style={{
        ...getGradiant(
          theme.primary,
          theme.secondary_darker,
          0
        )
      }}
    >
      <div
        className='flex justify-center items-center backdrop-blur-lg h-full w-full'
      >
        {loadingUser
          ? (connectionLost ? (
            <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 backdrop-blur-md">
              <div
                className="flex flex-col items-center px-8 py-5 border-[1px] rounded hover:pause duration-200"
                style={{
                  borderColor: theme.border
                }}
              >
                <div
                  className='mt-3 text-lg'
                  style={{
                    color: theme.inactive
                  }}
                >
                  You baka lost connection!
                </div>

                <button
                  className="duration-200 rounded px-4 py-1 mt-3"
                  style={{
                    backgroundColor: retryHovered
                      ? theme.deactivated
                      : theme.secondary,
                    color: theme.text
                  }}
                  onMouseEnter={() => setRetryHovered(true)}
                  onMouseLeave={() => setRetryHovered(false)}
                  onClick={() => window.location.reload(false)}
                >
                  Retry
                </button>
              </div>
            </div>
          ) : (
            <Loader size={'big'} user={user} />
          )
          ) : (
            <Routes>
              <Route path="/*" element={<Content user={user} loadingUser={loadingUser} />} />
            </Routes>
          )}
      </div>
    </div>
  );
}

export default Home;
