export default `
    *admin:
        %m;
    *

    *mod:
        accessDashboard;
        accessMaintenance;

        removeComments;
        removePosts;
        removePlaylists;
        removePostsFromPlaylists;
        removePostsFromSaves;
        removeAccounts;

        changeProfilePics;
        changeBios;
        changeBanners;
        changeBirthdays;
        changeUsernames;
        changeSettings;
        
        # References;
        ref:user;
    *

    *user:
        addComments;
        addPosts;
        addPlaylists;
        addPostsToOwnPlaylists;
        addPostsToOwnSaves;

        removeOwnComments;
        removeOwnPosts;
        removeOwnPlaylists;
        removePostsFromOwnPlaylists;
        removePostsFromOwnSaves;
        removeOwnAccount;

        viewOwnComments;
        viewComments;
        viewOwnPosts;
        viewPosts;
        viewOwnPlaylists;
        viewPlaylists;

        changeOwnProfilePic;
        changeOwnBio;
        changeOwnBanner;
        changeOwnBirthday;
        changeOwnUsername;
        changeOwnSettings;
    *
`