import { themes, getUserSettingsByRoot } from "../"

export default user => {

    const theme_data = getUserSettingsByRoot(user, [
        "apperiance",
        "display",
        "themes",
        "value"
    ])?.res || localStorage.getItem('theme') || 'blue'
    const dark_mode_data = getUserSettingsByRoot(user, [
        "apperiance",
        "display",
        "darkMode",
        "value"
    ])?.res || localStorage.getItem('darkMode') || true

    localStorage.setItem('theme', theme_data)
    localStorage.setItem('darkMode', dark_mode_data)

    const theme = themes
        [dark_mode_data ? 'dark' : 'light']
        [dark_mode_data ? `dark_${theme_data}` : `light_${theme_data}`]
        || themes.dark.dark_blue

    return theme

}