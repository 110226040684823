import { getRoleData, getPermissions } from "../"

export default roleName => {

    const permissions = getRoleData(roleName).permissions
    const result = []

    permissions.forEach(permission => {
        if (permission.match(/ref\:\w+/)) {
            getPermissions(permission.replace(/ref\:/, "")).forEach(_perm => {
                result.push(_perm)
            })
        } else {
            result.push(permission)
        }
    })

    return result

}