import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo_dark from '../assets/logo_dark.png';

import { onAuthStateChanged } from 'firebase/auth';
import { get, child, ref } from 'firebase/database';
import { db, auth } from '../client';

import { getGradiant, getTheme, getVersion } from '../utils';

const Dashboard = () => {
    const [version, setVersion] = useState(null);
    const [version_mode, setVersionMode] = useState(null);

    const [loginHovered, setLoginHovered] = useState(false);

    const navigate = useNavigate()

    const [gradiant, setGradiant] = useState(getGradiant(
        getTheme().primary,
        getTheme().secondary_darker,
        0
    ));
    const [theme, setTheme] = useState(getTheme());

    document.title = 'Hanicord • Maintenance'

    useEffect(() => {
        get(child(ref(db), 'system'))
            .then(snapshot => {

                const data = snapshot.val()

                if (snapshot.exists()) {

                    setVersion(data.version)
                    setVersionMode(data.version_mode)

                } else {

                    console.error('Version snapshot doesnt exist')

                }

            })

        onAuthStateChanged(auth, (user) => {

            if (user) {

                get(child(ref(db), 'users'))
                    .then(snapshot => {

                        if (snapshot.exists()) {

                            const data = snapshot.val()

                            for (let userId in data) {

                                let userData = data[userId];

                                if (user.uid === userId) {

                                    setTheme(getTheme(userData))
                                    setGradiant(getGradiant(
                                        getTheme(userData).primary,
                                        getTheme(userData).secondary_darker,
                                        0
                                    ))

                                }

                            }

                        } else {

                            console.error('Couldnt find user snapshot')

                        }

                    })

            }

        });

    }, []);

    return (
        <div
            className="select-none flex justify-start items-center flex-col h-screen"
            style={{
                ...gradiant
            }}
        >
            <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 backdrop-blur-md">
                <div
                    className="flex flex-col items-center px-8 py-5 border-[1px] rounded animate-bounce hover:pause duration-200"
                    style={{
                        borderColor: theme.border
                    }}
                >
                    <div
                        className='flex flex-row cursor-pointer'
                        title='Hanicord'
                    >
                        <img
                            src={logo_dark}
                            className='w-[50px]'
                        />

                        <div
                            className='flex flex-col justify-center mx-2'
                        >
                            <div
                                className='font-bold text-xl'
                            >
                                Hanicord
                            </div>
                            <div
                                className='text-sm'
                                style={{
                                    color: theme.border
                                }}
                            >
                                {getVersion(version, version_mode) || 'Version unavailable'}
                            </div>
                        </div>
                    </div>
                    <div
                        className='mt-3 text-lg'
                        style={{
                            color: theme.inactive
                        }}
                    >
                        Hanicord is currently
                    </div>
                    <div
                        className='text-lg'
                        style={{
                            color: theme.inactive
                        }}
                    >
                        under maintenance!
                    </div>

                    <button
                        className="duration-200 rounded px-4 py-1 mt-3"
                        style={{
                            backgroundColor: loginHovered
                                ? theme.deactivated
                                : theme.secondary,
                            color: theme.text
                        }}
                        onMouseEnter={() => setLoginHovered(true)}
                        onMouseLeave={() => setLoginHovered(false)}
                        onClick={() => {
                            auth.signOut()

                            window.location.href = 'login'
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;