export default {

    primary: "#0d0309",
    secondary_darker: "#26091b",
    secondary: "#381029",
    deactivated: "#750d48",
    interaction: "#a1035d",
    border: "#3b2a33",
    inactive: "#918490",
    text: "#FAFAFA",

}