import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { BiCategoryAlt, BiLogIn } from 'react-icons/bi';
import { ImSearch } from 'react-icons/im'

import { Loader } from './extra';
import { simplify, getTranslation, getTheme } from '../utils'

const Hamburger = ({ user, loadingUser }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const [categoriesHovered, setCategoriesHovered] = useState(false);
  const [loginHovered, setLoginHovered] = useState(false);
  const [searchHovered, setSearchHovered] = useState(false);

  const theme = getTheme(user);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <>
      {width < 600 ? (
        <div
          className='flex flex-row w-full border-b-[1px] h-[60px]'
          style={{
            borderColor: theme.border
          }}
        >
          <div
            className='flex flex-row justify-around w-full m-3'
          >
            {width > 300 ? (
              <div
                className='flex flex-row'
              >
                <div
                  className='flex flex-row rounded-full justify-center h-full mr-2'
                  style={{
                    backgroundColor: theme.primary
                  }}
                >
                  <BiCategoryAlt
                    className='cursor-pointer duration-200 mx-3 text-[20px] justify-center h-full'
                    style={{
                      color: categoriesHovered
                        ? theme.interaction
                        : theme.border
                    }}
                    onMouseEnter={() => setCategoriesHovered(true)}
                    onMouseLeave={() => setCategoriesHovered(false)}
                    title={getTranslation(user, 'home.filter')}
                  />
                  <input
                    type="text"
                    className='flex pl-3 my-auto h-5 border-l-[1px] rounded-r-full font-medium'
                    style={{
                      backgroundColor: theme.primary,
                      borderColor: theme.border,
                      color: theme.inactive,
                    }}
                    placeholder='Search...'
                    title={getTranslation(user, 'home.search')}
                  />
                </div>
                <div
                  className='m-auto'
                >
                  {loadingUser ? (
                    <div
                      className='flex mt-auto justify-center items-center w-full mb-5'
                    >
                      <Loader size='small' user={user} />
                    </div>
                  ) : (
                    <>
                      {user ? (
                        <Link
                          to={`user/${user.username.simplify()}`}
                          className='flex'
                          title={user.username}
                        >
                          <img
                            src={user.image}
                            className='rounded-full w-[25px] h-[25px] my-auto border-[1px]'
                            style={{
                              borderColor: theme.border
                            }}
                          />
                        </Link>
                      ) : (
                        <Link
                          to={'login'}
                          className='flex h-full justify-center'
                          title={getTranslation(user, 'home.login')}
                        >
                          <BiLogIn
                            className='text-[25px] my-auto duration-200'
                            style={{
                              color: loginHovered
                                ? theme.interaction
                                : theme.border
                            }}
                            onMouseEnter={() => setLoginHovered(true)}
                            onMouseLeave={() => setLoginHovered(false)}
                          />
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div
                  className='flex flex-row w-[30px]'
                >
                  <ImSearch
                    className='text-[22px] m-auto duration-200'
                    style={{
                      color: searchHovered
                        ? theme.interaction
                        : theme.border
                    }}
                    onMouseEnter={() => setSearchHovered(true)}
                    onMouseLeave={() => setSearchHovered(false)}
                  />
                </div>
                <div
                  className='flex flex-row w-[30px]'
                >

                  {user ? (
                    <Link
                      to={`user/${user.username.simplify()}`}
                      className='flex'
                      title={user.username}
                    >
                      <img
                        src={user.image}
                        alt="profile-image"
                        className='rounded-full w-[30px] h-[30px] my-auto'
                      />
                    </Link>
                  ) : (
                    <Link
                      to={'login'}
                      className='flex h-full justify-center'
                      title={getTranslation(user, 'home.login')}
                    >
                      <BiLogIn
                        className='text-[30px] my-auto duration-200'
                        style={{
                          color: loginHovered
                            ? theme.interaction
                            : theme.border
                        }}
                        onMouseEnter={() => setLoginHovered(true)}
                        onMouseLeave={() => setLoginHovered(false)}
                      />
                    </Link>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

      ) : (
        <div
          className='flex flex-row w-full border-b-[1px] h-[70px]'
          style={{
            borderColor: theme.border
          }}
        >

        </div>
      )}
    </>
  );
}

export default Hamburger;
