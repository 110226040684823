import { PSFS, toJSON } from "../"

export default String.prototype.toJSON = function (Key) {

    const values = {}
    const chars = this.replace(/    |\r\n|\r|\n/gm, "")
    var currentKey
    var valConst = []
    var keyConst = []
    var isComment = false
    var isObject = false
    var isKey = false

    for (let i in chars) {
        let char = chars[i]
        let nextChar = chars[i + 1]

        if (typeof char !== "string") continue

        if (char === '#') {

            isComment = true
            continue

        }
        if (isComment) {

            if (char === ';') {

                isComment = false

            }

            continue

        }

        if (char === '*') {

            isObject = true
            isKey = true

        } else if (isObject) {

            if (isKey) {

                keyConst.push(char)
                if (char === ':') {

                    isKey = false
                    currentKey = keyConst.join('').replace(':', '')
                    keyConst = []

                }

            } else {

                if (Key && currentKey !== Key) {
                    if (char === '*') {

                        isObject = false
                        valConst = []
                        currentKey = ''

                    }
                    continue
                }
                if (char === '*') {

                    isObject = false
                    valConst = []
                    currentKey = ''
                    continue

                }

                valConst.push(char)

                if (char === ';') {
                    /**
                     *? %s    -> :space:
                     *? %m    -> *
                     *? %sem  -> ;
                     *? %dp   -> :
                     *? %h    -> #
                     *? %br   -> \n
                     */
                    let currentVal = [valConst.join('').replace(';', '').replace(/\%s/g, ' ').replace(/\%m/g, '*').replace(/\%sem/g, ';').replace(/\%dp/g, ':').replace(/\%h/g, '#').replace(/\%br/g, '\n')]

                    currentVal = currentVal[0].split('->')
                    currentVal.forEach((val, i) => {
                        if (!isNaN(Number(val))) currentVal[i] = Number(val)
                        if (val === "undefined") currentVal[i] = undefined
                        if (val === "false") currentVal[i] = false
                        if (val === "null") currentVal[i] = null
                        if (val === "true") currentVal[i] = true
                        if (val === "NaN") currentVal[i] = NaN
                        if (val.match(/^ref\:.+$/)) {
                            let key = val.replace('ref:', '')
                            let ref_val

                            if (key.match(/^\@.+\:.+/)) {
                                let _key = key.replace(/\@.+\:/, '')
                                let file_name = key.replace('@', '').replace(/\:.+/, '')

                                PSFS.getData(file_name).toJSON(_key)[_key]?.forEach(value => {
                                    values[currentKey].push(value)
                                })
                            }

                            if (!ref_val) {
                                this.toJSON(key)[key]?.forEach(value => {
                                    values[currentKey].push(value)
                                })
                            }
                        }

                        // ! Important ! '-var;' has to be the last parameter in a psf file 
                        if (val.match(/^\-.+$/)) {
                            val = val.replace(/^\-/, '')
                            const index = values[currentKey].indexOf(val)
                            if (!isNaN(index)) values[currentKey].splice(index, 1);
                        }
                    });

                    if (currentVal[0].match(/^ref\:.+$/) || currentVal[0].match(/^\-.+$/)) {
                        valConst = []
                        continue
                    }
                    if (currentVal.length === 1) currentVal = currentVal[0]

                    let newVals = values[currentKey] || []
                    newVals.push(currentVal)
                    values[currentKey] = newVals
                    valConst = []

                } else if (nextChar === '*') {

                    throw new Error(`Syntax Error in key '${currentKey}'\nExpected ';' after ${valConst.join('')}`)

                }

            }

        }

    }

    return values
}