import en from './translations/en'
import de from './translations/de'
import ru from './translations/ru'

export default {

    en,
    de,
    ru

}