import { settings, settingsVersion } from "../"

export default () => {

    const default_user_settings_constructor = {

        _version: settingsVersion,

        data: settings.data.map(setting => {

            return {

                name: setting.name,

                modules: setting.modules.map(module => {

                    return {

                        name: module.name,

                        options: module.options.map(option => {

                            return {

                                name: option.name,
                                value: option.default_value,

                            }

                        })

                    }

                })

            }

        })

    }

    return default_user_settings_constructor

}